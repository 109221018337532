import { defineComponent, computed, ref, reactive, PropType, Ref } from 'vue'
import { MyTree } from '@myfront/tdesign-components'
import { DropdownContext, ICatalog, keys } from './types'
import { Button, Space, Input, Icon, Dropdown } from 'tdesign-vue-next'
import generateRandomString from '../../composables/generateRandomString'
import './style.css'
export default defineComponent({
  name: 'CatalogEditor',
  components: {
    TButton: Button,
    TSpace: Space,
    TInput: Input,
    TIcon: Icon,
    TDropdown: Dropdown,
  },
  props: {
    data: {
      type: Array as PropType<ICatalog[]>,
      required: true,
    }
  },
  emits: {
    submit: (value: unknown) => true,
    selected: (value: unknown) => true,
    // 实现整体树形数据的双向绑定的事件定义
    "update:data": (value: ICatalog[]) => true,
  },
  setup(props, ctx) {
    // 当前点击的catalog
    const section = ref<ICatalog>()
    const state = reactive<{
      data: ICatalog[] | undefined,
      activeId: string,
      useActived: boolean,
      currentEditNode: unknown
      // 当前编辑节点的label值，撤销时拿回
      currentEditNodeOriginalLabel?: string
      // 当前编辑节点的label值
      currentEditNodeLabel?: string

    }>({
      activeId: '',
      useActived: false,
      data: props.data,
      // 当前编辑的节点记录
      currentEditNode: null
    })

    const tree = ref<HTMLElement | null>(null)

    // 获取实际的t-tree实例
    const treeInstance = computed(() => {
      //@ts-expect-error 暂不处理
      return tree.value?.$refs.tree
    })


    const handle = (val: ICatalog) => {
      section.value = val
      ctx.emit('selected', section.value)
    }

    const getActivedNode = () => {
      const activeNode = treeInstance.value?.getItem(state.activeId);
      return activeNode;
    }

    const getInsertItem = () => {
      let item = null;
      if (state.useActived) {
        item = getActivedNode();
      } else {
        item = {
          [keys.value]: generateRandomString(),
          [keys.label]: '未命名',
        };
      }
      return item;
    }

    // 追加节点
    const append = (e: MouseEvent, node: Ref<number>) => {
      e.stopPropagation()
      const item = getInsertItem();
      if (item) {
        if (!node) {
          treeInstance.value?.appendTo('', item);
        } else {
          treeInstance.value?.appendTo(node.value, item);
        }
        setTimeout(() => {
          ctx.emit('update:data', walk(treeInstance.value.getTreeData()))
        })
      }
      if (state.useActived) {
        state.activeId = '';
      }
    }

    // 前插节点
    const insertBefore = (e: MouseEvent, node: Ref<number>) => {
      e.stopPropagation()
      const item = getInsertItem();
      if (item) {
        treeInstance.value?.insertBefore(node.value, item);
        setTimeout(() => {
          ctx.emit('update:data', walk(treeInstance.value.getTreeData()))
        })
      }
    }

    // 后插节点
    const insertAfter = (e: MouseEvent, node: Ref<number>) => {
      e.stopPropagation()
      const item = getInsertItem();
      if (item) {
        treeInstance.value.insertAfter(node.value, item);
        setTimeout(() => {
          ctx.emit('update:data', walk(treeInstance.value.getTreeData()))
        })
      }
    }

    // 移除节点
    const remove = (e: MouseEvent, node: Ref<number>) => {
      e.stopPropagation()
      treeInstance.value.remove(node.value);
      setTimeout(() => {
        ctx.emit('update:data', walk(treeInstance.value.getTreeData()))
      })
    }

    // 编辑节点
    const edit = (e: MouseEvent, node: any) => {
      e.stopPropagation()
      state.currentEditNode = node
      state.currentEditNodeLabel = node.label
      state.currentEditNodeOriginalLabel = node.label
    }

    // 更新节点
    const update = (val: string, node: any) => {
      state.currentEditNodeLabel = val
      node.setData({ [keys.label]: val, label: val })

      setTimeout(() => {
        ctx.emit('update:data', walk(treeInstance.value.getTreeData()))
      })
    }

    // 保存
    const save = (e: MouseEvent, node: any) => {
      e.stopPropagation()
      node.setData({ [keys.label]: state.currentEditNodeLabel, label: state.currentEditNodeLabel })
      state.currentEditNode = null
      setTimeout(() => {
        ctx.emit('update:data', walk(treeInstance.value.getTreeData()))
      })
    }

    // 变形数据，只保留原始数据的字段结构，去掉label, value的内部数据结构
    const walk = (item: any) => {
      return item.map((it: any) => {
        let children = []
        if (it.children) {
          children = walk(it.children)
        }

        return {
          [keys.label]: it[keys.label] || it.label,
          [keys.value]: it[keys.value] || it.value,
          [keys.children]: children,
        }
      })
    }

    // 撤销
    const cancel = (e: MouseEvent, node: any) => {
      e.stopPropagation()
      state.currentEditNode = null
      state.currentEditNodeLabel = state.currentEditNodeOriginalLabel
      node.setData({ [keys.label]: state.currentEditNodeOriginalLabel })
    }

    // TODO 增加history功能 方便单步撤回

    const dragEndHandler = () => {
      setTimeout(() => {
        ctx.emit('update:data', walk(treeInstance.value.getTreeData()))
      })
    }

    const opHandler = (ctx: DropdownContext, e: MouseEvent, node: any) => {
      switch (ctx.value) {
        case 1:
          append(e, node)
          break;
        case 2:
          insertBefore(e, node)
          break;
        case 3:
          insertAfter(e, node)
          break;
        case 4:
          edit(e, node)
          break;
        case 5:
          remove(e, node)
          break;
        default:
          console.log('nothing action to happen...')
      }
    }

    return () => {
      return <>
        {
          state.data && <MyTree
            class="my-catalog-editor"
            ref={tree}
            search-show={true}
            expandAll={true}
            data={state.data}
            activable={true}
            keys={keys}
            onTreeNode={handle}
            draggable={true}
            searchShow={false}
            onDragEnd={dragEndHandler}
            line={true}
          >
            {{
              operations: ({ node }) => {
                return <>
                  <t-space size={10}>
                    {/* <t-button size="small" variant="base" onClick={(e: MouseEvent) => append(e, node)}>添加子节点</t-button>
                  <t-button size="small" variant="outline" onClick={(e: MouseEvent) => insertBefore(e, node)}>前插节点</t-button>
                  <t-button size="small" variant="outline" onClick={(e: MouseEvent) => insertAfter(e, node)}>后插节点</t-button>
                  <t-button size="small" variant="outline" onClick={(e: MouseEvent) => edit(e, node)}>编辑节点</t-button>
                  <t-button size="small" variant="base" theme="danger" onClick={(e: MouseEvent) => remove(e, node)}>删除</t-button> */}
                    <t-dropdown options={[{
                      content: '添加子节点',
                      value: 1,
                    },
                    {
                      content: '前插节点',
                      value: 2,
                    },
                    {
                      content: '后插节点',
                      value: 3,
                    },
                    // {
                    //   content: '编辑节点',
                    //   value: 4,
                    // }, 
                    {
                      content: '删除节点',
                      value: 5,
                    }]} onClick={(ctx: MouseEvent, { e }) => opHandler(ctx, e, node)}>
                      <t-button theme="default" variant="outline" shape="square">
                        <t-icon slot="icon" name="ellipsis" size="16" />
                      </t-button>
                    </t-dropdown>
                  </t-space>
                </>
              },
              label: ({ node }) => {
                if (state.currentEditNode && node.value === state.currentEditNode.value) {
                  return <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <t-input value={state.currentEditNodeLabel} onEnter={(val: string, { e }) => save(e, node)} onChange={(val: string) => update(val, node)} />
                      <t-button theme="default" onClick={(e: MouseEvent) => save(e, node)} style={{ marginLeft: '4px' }} size="small">
                        <t-icon slot="icon" style={{ color: 'green' }} name="check" />
                      </t-button>
                      <t-button theme="default" onClick={(e: MouseEvent) => cancel(e, node)} style={{ marginLeft: '4px' }} size="small">
                        <t-icon slot="icon" style={{ color: 'gray' }} name="rollback" />
                      </t-button>
                    </div>
                  </>
                } else {
                  return <div onDblclick={() => { state.currentEditNode = node; state.currentEditNodeLabel = node.label }}>{node.label}</div>
                }
              }
            }}
          </MyTree>
        }
      </>
    }
  }
})